import { useTranslation } from 'react-i18next';
import { convertMonthEnumToNumber, convertMonthNumberToEnum, getMonthKeys, useMonths } from './Months';
import { Option } from 'fgirot-k2-ui-components';
import { MonthDay } from 'src/types/DayMonth';
import { Month } from 'src/types/Month';
import { DateTime } from 'luxon';

export const periodToDate = (period: string): Date => {
  return new Date(period.substring(0, 4) + '-' + period.substring(4, 6) + '-01');
};

export const formatPeriodToDate = (period: string): string => {
  return `${period.substring(0, 4)}-${period.substring(4, 6)}`;
};

export const getCurrentPeriod = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');

  return `${year}${month}`;
};

export const usePeriodText = (period: string) => {
  const { t } = useTranslation('common');
  if (!period) {
    return undefined;
  }
  const periodAsDate = periodToDate(period);
  const months = getMonthKeys();
  const monthName = months[periodAsDate.getMonth()];
  return `${t(monthName)} ${periodAsDate.getFullYear()}`;
};

export const lastDayInReportPeriod = (periodAsDate: Date): Date => {
  return new Date(periodAsDate.getFullYear(), periodAsDate.getMonth() + 1, 0);
};

export const parseReportPeriod = (year: number, month: number): string => {
  return `${year}${month > 9 ? month : '0' + month}`;
};

export const formatDateTimeString = (dateTimeString: string) => {
  if (!dateTimeString) {
    return undefined;
  }

  const obj = DateTime.fromISO(dateTimeString, { zone: 'UTC' }).setLocale('sv-SE').setZone('Europe/Stockholm');

  return obj.invalidExplanation ? undefined : obj.toLocaleString(DateTime.DATETIME_MED);
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateStringToYearMonth = (date: string) => {
  const year = date?.slice(0, 4);
  const month = date?.slice(5, 7).padStart(2, '0');

  return `${year}-${month}`;
};

export const getFormattedDateStringFromPeriod = (period: string) => {
  const months = useMonths();
  const year = period?.slice(0, 4);
  const month = +period?.slice(4, 6) - 1;
  return `${months[month]?.[0].toUpperCase() + months[month]?.slice(1)} ${year}`;
};

export const getDaysFromMonthWithoutLeapYear = (year: number, month: Month): Option<number>[] => {
  const monthNumber = convertMonthEnumToNumber(month);
  let numberOfDays = new Date(year, monthNumber, 0).getDate();

  // If Feb, remove date 29
  if (monthNumber === 2) {
    numberOfDays = 28;
  }
  const options: Option<number>[] = [];
  for (let i = 1; i <= numberOfDays; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};

export const getMonthDayOptionsFromMonth = (month: Month): Option<number>[] => {
  const monthNumber = convertMonthEnumToNumber(month);
  const numberOfDays = new Date(2022, monthNumber, 0).getDate();
  const options: Option<number>[] = [];
  for (let i = 1; i <= numberOfDays; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};
export const getMonth = (monthDay: MonthDay): Month =>
  convertMonthNumberToEnum(parseInt(monthDay.replace('-', '').slice(1, 3)));
export const getDay = (monthDay: MonthDay): number => parseInt(monthDay.replace('-', '').slice(4, 6));

export const toMonthDay = (month: number, day: number): MonthDay =>
  `--${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`;

export const getPeriodDates = (date: string): [fromDate: string, toDate: string] => {
  const fromDate = periodToDate(date);
  const toDate = lastDayInReportPeriod(fromDate);

  return [formatDate(fromDate).replaceAll('-', ''), formatDate(toDate).replaceAll('-', '')];
};

export const getPeriodsBetween = (fromPeriod: string, toPeriod: string): string[] => {
  const periods: string[] = [];
  const fromDate = periodToDate(fromPeriod);
  const toDate = periodToDate(toPeriod);

  while (fromDate <= toDate) {
    periods.push(`${fromDate.getFullYear()}${(fromDate.getMonth() + 1).toString().padStart(2, '0')}`);
    fromDate.setMonth(fromDate.getMonth() + 1);
  }

  return periods;
};

export const numberOfDaysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const getPreviousPeriod = (period: string): string => {
  if (period.slice(4, 6) === '01') {
    return `${parseInt(period.slice(0, 4)) - 1}12`;
  }

  const month = parseInt(period.slice(4, 6)) - 1;
  const year = period.slice(0, 4);
  return `${year}${month > 9 ? month : '0' + month}`;
};

export const getPeriodYearString = (period: string): string => {
  const year = period?.slice(0, 4);

  if (!year) {
    return null;
  }

  return year;
};

export const getPeriodYear = (period: string): number => {
  const year = period?.slice(0, 4);

  if (!year) {
    return null;
  }

  return parseInt(year);
};

export const getPeriodMonthString = (period: string): string => {
  const month = period?.slice(4, 6);

  if (!month) {
    return null;
  }

  return month;
};
