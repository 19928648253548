import { Icon, ListGroup, Tooltip, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import When from 'src/components/Common/When';
import { Employee } from 'src/types/Employee';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { EmployeeHistory } from 'src/types/employees/EmployeeHistory';
import { stringOrBlank } from 'src/util/StringUtil';

interface EmploymentDetailsProps {
  employee: Employee;
  employeeCard: AggregatedEmployeeCard;
  employeeHistory: EmployeeHistory[];
}

const EmploymentDetails: FC<EmploymentDetailsProps> = ({ employee, employeeCard, employeeHistory }) => {
  const { t } = useTranslation();

  const earliestStartDate = employeeHistory?.find(
    (hist) => hist?.earliestEmploymentStartDate < employee?.earliestEmploymentStartDate,
  )?.earliestEmploymentStartDate;

  return (
    <ListGroup variant="inline-edit" className="employment-details-overview-tab__list">
      <ListProperty label={t('employees:employmentNumber')} value={employee.employmentNumber} />
      <ListProperty label={t('employees:employmentStartDate')} value={employee.employmentStartDate} />
      <ListProperty label={t('employees:employmentEndDate')} value={employee.employmentEndDate} />

      <ListProperty
        label={
          <div className="employment-details-overview-tab__label-with-info">
            <Typography className="employment-details-overview-tab__label" bold component="span" variant="body2">
              {t('employees:earliestEmploymentStartDate')}
            </Typography>
            <When condition={employeeHistory !== null && earliestStartDate < employee.earliestEmploymentStartDate}>
              <Tooltip content={t('employees:tool-tip-content')} direction="bottom">
                <Icon className="employment-details-overview-tab__info-icon" type="Info" />
              </Tooltip>
            </When>
          </div>
        }
        value={employee.earliestEmploymentStartDate}
      />

      <ListProperty
        label={t('employees:employmentCategory')}
        value={t(`employmentCategories:${employeeCard?.employmentCategory}`)}
      />
      <ListProperty
        label={t('employees:employmentType')}
        value={t(`employmentTypes:${employeeCard?.employmentType}`)}
      />
      <ListProperty
        label={t('employees:remunerationType')}
        value={t(`remunerationTypes:${employeeCard?.remunerationType}`)}
      />
      <ListProperty label={t('employees:economyDepartment')} value={stringOrBlank(employee?.accountingUnit?.name)} />
      <ListProperty
        label={t('employees:employmentPercentage')}
        value={employeeCard?.workingPercentage && `${employeeCard.workingPercentage}%`}
      />
      <ListProperty
        label={t('employees:entitledVacationDays')}
        value={stringOrBlank(employeeCard?.entitledVacationDays)}
        data-cy="entitledVacationDays"
      />
      <ListProperty label={t('employees:partTimePension')} value={t(`common:${employeeCard.partTimePension}`)} />
      <ListProperty
        label={t('employees:hasSickAndActivityCompensation')}
        value={t(`common:${employeeCard.hasSickAndActivityCompensation}`)}
      />
    </ListGroup>
  );
};

export default EmploymentDetails;
