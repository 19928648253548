import { gql } from '@apollo/client';

export const UPSERT_DRAFT_EMPLOYEE_CARD = gql`
  mutation UpsertDraftEmployeeCard($request: UpsertDraftEmployeeCard!) {
    upsertDraftEmployeeCard(request: $request) {
      id
      employeeId
      evaluationPolicyEntityId
      employmentGroupId
      employmentCategory
      remunerationType
      sickDays
      workingPercentage
      employmentType
      isDraft
      hasSickAndActivityCompensation
    }
  }
`;
