import React from 'react';
import { useTranslation } from 'react-i18next';
import EditableProperty from '../EditableProperty';
import { createOptions } from 'src/util/OptionUtil';
import UpdateSelect from 'src/components/Report/Common/UpdateField/UpdateSelect';
import { remunerationTypes, RENUMERATION_TYPE_UNKNOWN } from 'src/types/RemunerationType';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { employmentCategories, UNKNOWN_EMPLOYMENT_CATEGORY } from 'src/types/EmploymentCategory';
import { employmentTypes } from 'src/types/EmploymentTypes';
import TextInput from 'src/components/Common/TextInput';

export interface EmployeeCardInputProps {
  employeeCardToEdit: AggregatedEmployeeCard;
  activeEmployeeCard: AggregatedEmployeeCard;
  employeeCardDraft: AggregatedEmployeeCard;
  handleChange: (key: string, value: string) => void;
  type: EmployeeCardInputType;
}

export type EmployeeCardInputType =
  | 'employmentCategory'
  | 'employmentType'
  | 'remunerationType'
  | 'workingPercentage'
  | 'entitledVacationDays'
  | 'partTimePension'
  | 'hasSickAndActivityCompensation';

function EmployeeCardInput({
  employeeCardToEdit,
  activeEmployeeCard,
  handleChange,
  employeeCardDraft,
  type,
}: EmployeeCardInputProps) {
  const { t } = useTranslation();

  return (
    <EditableProperty
      label={t(`employees:${type}`)}
      hasChanged={activeEmployeeCard?.[type] !== employeeCardDraft[type]}
    >
      <Input type={type} handleChange={handleChange} employeeCardToEdit={employeeCardToEdit} />
    </EditableProperty>
  );
}

interface InputProps {
  type: EmployeeCardInputType;
  handleChange: (key: string, value: string) => void;
  employeeCardToEdit: AggregatedEmployeeCard;
}

function Input({ type, handleChange, employeeCardToEdit }: InputProps) {
  const { t } = useTranslation();

  switch (type) {
    case 'employmentCategory': {
      const filteredEmploymentCategories = employmentCategories.filter(
        (empCat) =>
          employeeCardToEdit.employmentCategory === UNKNOWN_EMPLOYMENT_CATEGORY ||
          empCat !== UNKNOWN_EMPLOYMENT_CATEGORY,
      );

      return (
        <UpdateSelect
          fieldKey="employmentCategory"
          fieldValue={employeeCardToEdit.employmentCategory}
          options={createOptions(filteredEmploymentCategories, t, 'employmentCategories')}
          onClick={handleChange}
          data-cy={`${type}-input`}
        />
      );
    }
    case 'employmentType':
      return (
        <UpdateSelect
          fieldKey="employmentType"
          fieldValue={employeeCardToEdit.employmentType}
          options={createOptions(employmentTypes, t, 'employmentTypes')}
          onClick={handleChange}
          data-cy={`${type}-input`}
        />
      );
    case 'remunerationType': {
      const filteredRemunerationTypes = remunerationTypes.filter(
        (remunerationType) =>
          employeeCardToEdit.remunerationType === RENUMERATION_TYPE_UNKNOWN ||
          remunerationType !== RENUMERATION_TYPE_UNKNOWN,
      );
      return (
        <UpdateSelect
          fieldKey="remunerationType"
          fieldValue={employeeCardToEdit.remunerationType}
          options={createOptions(filteredRemunerationTypes, t, 'remunerationTypes')}
          onClick={handleChange}
          data-cy={`${type}-input`}
        />
      );
    }
    case 'workingPercentage':
      return (
        <TextInput
          type="number"
          validationKey="percentage"
          value={employeeCardToEdit.workingPercentage.toString()}
          onChange={(e) => handleChange('workingPercentage', e.target.value)}
          min="0"
          max="100"
          data-cy={`${type}-input`}
        />
      );
    case 'entitledVacationDays':
      return (
        <TextInput
          type="number"
          validationKey="integer"
          value={employeeCardToEdit.entitledVacationDays?.toString()}
          onChange={(e) => handleChange('entitledVacationDays', e.target.value)}
          min="0"
          data-cy={`${type}-input`}
        />
      );
    case 'partTimePension':
      return (
        <UpdateSelect
          fieldKey="partTimePension"
          fieldValue={employeeCardToEdit.partTimePension.toString()}
          options={[
            {
              value: 'true',
              label: t('common:yes'),
            },
            {
              value: 'false',
              label: t('common:no'),
            },
          ]}
          onClick={handleChange}
          data-cy={`${type}-input`}
        />
      );
    case 'hasSickAndActivityCompensation': {
      return (
        <UpdateSelect
          fieldKey="hasSickAndActivityCompensation"
          fieldValue={
            !employeeCardToEdit.hasSickAndActivityCompensation
              ? 'false'
              : employeeCardToEdit.hasSickAndActivityCompensation.toString()
          }
          options={[
            {
              value: 'true',
              label: t('common:yes'),
            },
            {
              value: 'false',
              label: t('common:no'),
            },
          ]}
          onClick={handleChange}
          data-cy={`${type}-input`}
        />
      );
    }
  }
}

export default EmployeeCardInput;
