import { Modal, Card, Select, CardAction, Button, Option } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateBalancingOfBooksAdjustment } from 'src/service/accountingunit/BalancingOfBooksAdjustment';
import { Month } from 'src/types/Month';
import {
  BalancingOfBooksAdjustment,
  CreateBalancingOfBooksAdjustmentInput,
} from 'src/types/accountingunit/BalancingOfBooksAdjustment';
import { useMonthOptionsWithFilter } from 'src/util/date/Months';
import { getDaysFromMonthWithoutLeapYear, getPeriodYear } from 'src/util/date/PeriodUtil';
import { CurrentFinancialYear } from 'src/types/accountingunit/CurrentFinancialYear';
import { useTranslation } from 'react-i18next';
const getUniqueMonthsFromAdjustments = (adjustments: BalancingOfBooksAdjustment[]): Month[] => {
  return [...new Set(adjustments.map((adjustment) => adjustment.month))];
};

interface AddBalancingOfBooksAdjustmentModalProps {
  open: boolean;
  onClose: () => void;
  balancingOfBooksSettingsId: string;
  balancingOfBooksAdjustments: BalancingOfBooksAdjustment[];
  currentFinancialYear: CurrentFinancialYear;
}

const AddBalancingOfBooksAdjustmentModal: FC<AddBalancingOfBooksAdjustmentModalProps> = ({
  open,
  onClose,
  balancingOfBooksSettingsId,
  balancingOfBooksAdjustments,
  currentFinancialYear,
}) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const createBalancingOfBooksAdjustment = useCreateBalancingOfBooksAdjustment();
  const [createBalancingOfBooksAdjustmentInput, setCreateBalancingOfBooksAdjustmentInput] =
    useState<CreateBalancingOfBooksAdjustmentInput>({
      balancingOfBooksSettingsId,
      month: null,
      day: 1,
    });
  const handleModalClose = () => {
    setCreateBalancingOfBooksAdjustmentInput({
      balancingOfBooksSettingsId,
      month: null,
      day: 1,
    });
    onClose();
  };

  const monthsToFilter = getUniqueMonthsFromAdjustments(balancingOfBooksAdjustments);
  const monthOptions = useMonthOptionsWithFilter(monthsToFilter);
  const year = getPeriodYear(currentFinancialYear.fromDate);
  const monthDayOptions = getDaysFromMonthWithoutLeapYear(year, createBalancingOfBooksAdjustmentInput.month);
  const handleMonthChange = (option: Option<Month>) => {
    setCreateBalancingOfBooksAdjustmentInput((prev) => ({ ...prev, month: option.value, day: 1 }));
  };
  const handleDayChange = (option: Option<number>) => {
    setCreateBalancingOfBooksAdjustmentInput((prev) => ({ ...prev, day: option.value }));
  };

  const handleCreateBalancingOfBookAdjustment = () => {
    createBalancingOfBooksAdjustment(createBalancingOfBooksAdjustmentInput, employerId).then(handleModalClose);
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleModalClose}>
      <Card
        title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.title')}
        defaultClose
        onClose={handleModalClose}
      >
        <Select
          data-cy="adjustment-month-select"
          placeholder={t('common:choose-month')}
          options={monthOptions}
          alphabetical={false}
          selected={monthOptions.find((option) => option.value === createBalancingOfBooksAdjustmentInput.month)}
          onChange={handleMonthChange}
        />

        <Select
          data-cy="adjustment-day-select"
          disabled={!createBalancingOfBooksAdjustmentInput.month}
          placeholder={t('common:choose-day')}
          alphabetical={false}
          options={monthDayOptions}
          selected={monthDayOptions.find((option) => option.value === createBalancingOfBooksAdjustmentInput.day)}
          onChange={handleDayChange}
        />
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleModalClose} />
          <Button
            label={t('common:add')}
            onClick={handleCreateBalancingOfBookAdjustment}
            data-cy="create-adjustment-button"
            disabled={!(createBalancingOfBooksAdjustmentInput.month && createBalancingOfBooksAdjustmentInput.day)}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddBalancingOfBooksAdjustmentModal;
