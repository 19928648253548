import { Button, Card, Icon, Option, Select, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useDeleteBalancingOfBooksAdjustment,
  useUpdateBalancingOfBooksAdjustment,
} from 'src/service/accountingunit/BalancingOfBooksAdjustment';
import { BalancingOfBooksAdjustment } from 'src/types/accountingunit/BalancingOfBooksAdjustment';
import { getDaysFromMonthWithoutLeapYear, getPeriodYear } from 'src/util/date/PeriodUtil';
import AddBalancingOfBooksAdjustmentModal from './AddBalancingOfBooksAdjustmentModal';
import { useTranslation } from 'react-i18next';
import { CurrentFinancialYear } from 'src/types/accountingunit/CurrentFinancialYear';

interface MonthlyAdjustmentsCardProps {
  balancingOfBooksSettingsId: string;
  balancingOfBooksAdjustments: BalancingOfBooksAdjustment[];
  currentFinancialYear: CurrentFinancialYear;
}

const MonthlyAdjustmentsCard: FC<MonthlyAdjustmentsCardProps> = ({
  balancingOfBooksSettingsId,
  balancingOfBooksAdjustments,
  currentFinancialYear,
}) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const updateBalancingOfBooksAdjustment = useUpdateBalancingOfBooksAdjustment();
  const deleteBalancingOfBooksAdjustment = useDeleteBalancingOfBooksAdjustment();

  const toggleModalOpen = () => setModalOpen((prev) => !prev);

  return (
    <Card
      title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.adjustments-title')}
      className="accounting-settings-tab__card"
    >
      {balancingOfBooksAdjustments.map((adjustment) => {
        const handleAdjustmentDayChange = (option: Option<number>) => {
          updateBalancingOfBooksAdjustment(
            {
              balancingOfBooksAdjustmentId: adjustment.id,
              day: option.value,
            },
            employerId,
          );
        };

        const handleDeleteAdjustment = () => {
          deleteBalancingOfBooksAdjustment(adjustment.id, employerId);
        };
        const year = getPeriodYear(currentFinancialYear.fromDate);
        const options = getDaysFromMonthWithoutLeapYear(year, adjustment.month);

        return (
          <div key={`MonthlyAdjustmentRow-${adjustment.id}`} className="accounting-settings-tab__card-content-row">
            <Typography variant="h3" className="accounting-settings-tab__card-content-label">
              {t(`common:months.${adjustment.month}`)}
            </Typography>
            <Select
              className="accounting-settings-tab__card-content-label"
              alphabetical={false}
              options={options}
              selected={options.find((option) => option.value === adjustment.day)}
              onChange={handleAdjustmentDayChange}
            />
            <Button
              data-cy={`delete-${adjustment.month}-adjustment-button`}
              size="small"
              className="accounting-settings-tab__delete-button"
              icon={<Icon type="Trash" />}
              type="secondary"
              onClick={handleDeleteAdjustment}
            />
          </div>
        );
      })}
      <Button
        data-cy="add-adjustment-button"
        label={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.add-adjustment')}
        onClick={toggleModalOpen}
      />
      <AddBalancingOfBooksAdjustmentModal
        data-cy="add-adjustment-button"
        open={modalOpen}
        onClose={toggleModalOpen}
        balancingOfBooksSettingsId={balancingOfBooksSettingsId}
        balancingOfBooksAdjustments={balancingOfBooksAdjustments}
        currentFinancialYear={currentFinancialYear}
      />
    </Card>
  );
};

export default MonthlyAdjustmentsCard;
